
import { defineComponent } from 'vue';
import HomeLoanCalc from './components/HomeLoanCalc.vue';

export default defineComponent({
    name: 'App',
    components: {
        HomeLoanCalc,
    },
});
